import * as React from "react"
import { css } from "@emotion/react"

const HeaderImage = ({node}) => {
  if (typeof node.relationships.field_header_image != 'undefined' &&  node.relationships.field_header_image !== null) {
    const headerImageURL = node.relationships.field_header_image?node.relationships.field_header_image.localFile.childImageSharp.gatsbyImageData.images.fallback.src:false;
    const headerImageURLMobile = node.relationships.field_mobile_header_image?node.relationships.field_mobile_header_image.localFile.childImageSharp.gatsbyImageData.images.fallback.src:headerImageURL;
    const headerText = !node.field_header_text?node.title:node.field_header_text

    return <section className="section-hero" >
      <h1 className="container">{headerText}</h1>
      <div className={'section-hero__bg-desktop'} css={{ backgroundImage: `url(${headerImageURL})` }}></div>
      <div className={'section-hero__bg-mobile'} css={{ backgroundImage: `url(${headerImageURLMobile})` }}></div>
    </section>
  }
}

export default HeaderImage